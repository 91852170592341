let lang = {
    it: {
        slider: {
          captionbig : 'Perfezione.',
          captionsmall : 'Il nostro <span>valore aggiunto</span>',
          list : ['lipstick','mascara','skincare']
        },
        homepage: {
          meta: {
            title: 'Cosmelux',
            description: 'homepage description'
          },
          perfectfinishing: {
            title: 'Finitura perfetta.',
            description: ` Ottime resistenze chimiche e meccaniche.<br/><br/>
                           Infinità di <strong>colori ed effetti</strong>, rispettando l’ambiente e garantendo un servizio di qualità ed estetica <strong>personalizzata</strong> con possibile successivo dettaglio in tampografia, serigrafia, stampa a caldo o laser.`
          },
          icons:{
            magicwand: {
              title: 'pre-trattamento superfici',
              description: 'Pulizia in ambiente controllato con fiammatura, ionizzazione e spazzolatura'
            },            
            star: {
              title: 'capacità produttiva',
              description: 'Per pezzi di dimensione standard: 2.000.000 pezzi al giorno.'
            },            
            diamond: {
              title: 'superfici preziose',
              description: 'che potranno essere decorate in tampografia, serigrafia, stampa a caldo o laser.'
            },            
            bucket: {
              title: 'personalizzazione',
              description: 'Possibilità di ottenere un’infinità di colori e di “effetti”.'
            }
          },
          carousel: [
            {
              title: 'Lavorazione',
              description: 'Gli impianti di verniciatura dedicati al settore della cosmetica sono caratterizzati da pre-trattamento di pulizia con fiammatura, ionizzazione, spazzolatura e applicazione di prodotti vernicianti in ambiente controllato (umidità, temperatura, filtrazione aria) con postazioni multiple di verniciatura, asciugatura in forni ad aria forzata con controllo di temperatura, e infine polimerizzazione con lampade a UV. Successivamente sarà possibile decorare il prodotto in tampografia, serigrafia, stampa a caldo o laser.'
            }
          ],
        },
        contactform: {
          namelastname: 'nome e cognome*',
          company: 'azienda',
          emailaddress: 'indirizzo e-mail*',
          phone: 'telefono',
          message: 'messaggio*',
          privacy: `Dichiaro di aver letto l'{0}*`,
          privacyu: 'informativa sulla privacy',
          newsletter: 'Acconsento quindi al trattamento dei miei dati personali da parte di Cosmelux s.r.l per finalità di marketing.',
          requiredfields: 'I campi contrassegnati con * sono obbligatori',
          sendbutton: 'invia',
          thx: `Gentile {0}, grazie per averci contattato. Le risponderemo quanto prima all'indirizzo {1}`,
          error: 'Alcuni dati sono incompleti o mancanti'
        },
        careerform: {
          firstname: 'nome*',
          lastname: 'cognome*',
          emailaddress: 'indirizzo e-mail*',
          cvupload: 'carica il curriculum vitae*',
          phone: 'telefono*',
          message: 'lettera di presentazione*',
          privacy: `Dichiaro di aver letto l'{0}*`,
          privacyu: 'informativa sulla privacy',
          newsletter: 'Acconsento quindi al trattamento dei miei dati personali da parte di Cosmelux s.r.l per finalità di marketing.',
          requiredfields: 'I campi contrassegnati con * sono obbligatori',
          sendbutton: 'candidati',
          thx: `Gentile {0}, grazie per averci contattato. Le risponderemo quanto prima all'indirizzo {1}`,
          error: 'Alcuni dati sono incompleti o mancanti'
        },
        references: {
          meta: {
            title: 'referenze title',
            description: 'referenze description'
          },
          title: {
            small:'le nostre',
            text:'Referenze'
          }
        },
        realizations: {
          meta: {
            title: 'realizzazioni title',
            description: 'realizzazioni description'
          },
          title: {
            small:'cosmelux',
            text:'Realizzazioni'
          },
          filterbar: 'Filtra per',
          filterbuttons: ['tutto','lipstick','mascara','miniature','profumi','skin care','smalti','penne']
        },
        uvmetallization: {
          meta: {
            title: 'metallizzazione uv title',
            description: 'metallizzazione uv description'
          },
          title: {
            small: 'servizi',
            text: 'Metallizzazione UV',
            list: ['profumi','creme']
          },
          intro: {
            text : `Le 9 linee di <strong>metallizzazione sotto vuoto</strong> (7 PVD e 2 Sputtering) sono state studiate per garantire efficienza e qualità per garantire il migliore rapporto qualità/prezzo ai nostri clienti.<br/><br/>
                    L’ultima generazione di macchina di metallizzazione con tecnologia “Sputtering” ha permesso a Cosmelux di unire completamente i processi di verniciatura e di metallizzazione.`
          },
          advantages: {
            title: 'Vantaggi',
            list: [
              'Ottime resistenze chimiche (ai profumi, alle creme ecc…)',
              'Ottime resistenze meccaniche (al graffio, all’abrasione, allo sfregamento…)',
              'Qualità estetica molto elevata (dovuta ai mezzi di produzione moderni usati per la lavorazione)',
              'Il trattamento UV non viene eseguito ad alte temperatura quindi permette di mantenere le dimensione dei pezzi da trattare (su materiali standard)',
              'Possibilità di ottenere un’infinità di colori e di “effetti”',
              'Rispetto dell’ambiente: i prodotti usati non contengono metalli pesanti. Non vengono emessi solventi in atmosfera (usiamo delle vernici ad alto residuo secco e bruciamo i solventi con un termo combustore)',
              'Possibilità di usare vernici molto elastiche per mantenere l’elasticità del prodotto',
              'Possibilità di usare vernici che potranno essere decorate successivamente in tampografia, serigrafia, stampa a caldo o laser.'
            ]
          },
          productivecapacity: {
            title: '<strong>Capacità</strong> produttiva',
            text: 'Capacità Produttiva per pezzi di dimensione standard: 2.000.000 pezzi al giorno.'
          },
          piecestype: {
            title: 'Tipo di <strong>pezzi trattabili</strong>',
            text: 'Diametro: 5 – 120 mm<br/>Altezza: 5 – 140 mm<br/>Materiali: PP, ABS, PCTA, PE, PETG, PS, SAN, Alu, vetro, Surlyn'
          },
          companycert: {
            title: '<strong>Certificazioni</strong> azienda',
            text: 'DIN ISO 9001:2015'
          },
          termevapimplant: {
            title: {
              small: 'PVD',
              text: 'Impianti evaporazione termica'
            },
            description: `Nel campo della metallizzazione legata all’aspetto decorativo, l'adozione di impianti ad evaporazione termica è ancora oggi il sistema più diffuso per la semplicità del suo utilizzo e per la sua efficacia di deposizione in presenza di substrati con geometrie molto complicate.<br/><br/>
                          La fonte di deposizione consiste in resistenze in tungsteno, generalmente poste al centro della camera di processo, sulle quali viene alloggiato il filo del metallo da depositare.<br/><br/>
                          I substrati sono posizionati attorno alle resistenze e vengono fatti ruotare (rotazione e traslazione) durante la fase di deposizione del metallo.<br/><br/>
                          Per ottenere la deposizione del metallo all’interno della camera di processo è necessario raggiungere un grado di vuoto generalmente nsuperiore a 4x10-4 mbar (alto vuoto).<br/><br/>
                          Una volta raggiunto il grado di vuoto necessario inizia la vera e propria fase di evaporazione che consiste nel riscaldamento delle resistenze in tungsteno che, rese incandescenti, fondono il metallo il quale evapora andando a condensarsi sulla superficie del substrati.`
          },
          sputtering: {
            title: {
              small: 'PVD',
              text: 'Sputtering'
            },
            description: `<strong>Moderno</strong>, <strong>veloce</strong> ed <strong>economico</strong> sistema di deposizione dei metalli in origine utilizzato unicamente su substrati con superfici piane, oggi invece è sempre più utilizzato anche su oggetti tridimensionali, anche se con geometrie semplici.<br/><br/>

            Nella tecnologia sputtering la fonte di deposizione è rappresentata da un catodo magnetico sul quale viene alloggiato una piastra metallica (target) del materiale che si desidera depositare.<br/><br/>
            
            Il catodo magnetico viene generalmente collocato a lato della camera di processo. I substrati ruotano davanti alla fonte di deposizione. Raggiunto il grado di vuoto desiderato si applica un alto voltaggio e si immette Argon.<br/><br/>
            
            Gli ioni positivi di Argon subiscono un processo di accelerazione sul catodo negativo ed in seguito espellono gli atomi della piastra metallica.
            
            Questi atomi vanno poi a condensare sui substrati.`
          }
        },
        uvlacquering: {
          meta: {
            title: 'Cosmelux - laccatura UV dedicata al settore della cosmetica',
            description: ''
          },
          title: {
            small: 'servizi',
            text: 'Laccatura UV',
            list: ['profumi','creme']
          },
          intro: {
            text : 'Le 6 linee di <strong>verniciatura UV</strong> sono realizzate su progettazione interna con capacità produttiva fino a 120.000 pezzi/turno per linea.<br/><br/>Gli impianti di verniciatura dedicati al settore della cosmetica sono caratterizzati da pre-trattamento di pulizia con fiammatura, ionizzazione, spazzolatura e applicazione di prodotti vernicianti in ambiente controllato (umidità, temperatura, filtrazione aria) con postazioni multiple di verniciatura, asciugatura in forni ad aria forzata con controllo di temperatura, e infine polimerizzazione con lampade a UV.'
          },
          advantages: {
            title: 'Vantaggi',
            list: [
              'Ottime resistenze chimiche (ai profumi, alle creme ecc…),',
              'Ottime resistenze meccaniche (al graffio, all’abrasione, allo sfregamento…)',
              'Qualità estetica molto elevata (dovuta ai mezzi di produzione moderni usati per la lavorazione)',
              'Il trattamento UV non viene eseguito ad alte temperatura quindi permette di mantenere le dimensione dei pezzi da trattare (su materiali standard)',
              'Possibilità di ottenere un’infinità di colori e di “effetti”',
              'Rispetto dell’ambiente: i prodotti usati non contengono metalli pesanti. Non vengono emessi solventi in atmosfera (usiamo delle vernici ad alto residuo secco e bruciamo i solventi con un termo combustore)',
              'Possibilità di usare vernici molto elastiche per mantenere l’elasticità del prodotto',
              'Possibilità di usare vernici che potranno essere decorate successivamente in tampografia, serigrafia, stampa a caldo o laser.'
            ]
          },
          productivecapacity: {
            title: '<strong>Capacità</strong> produttiva',
            text: 'Capacità Produttiva per pezzi di dimensione standard: 2.200.000 pezzi al giorno.'
          },
          piecestype: {
            title: 'Tipo di <strong>pezzi trattabili</strong>',
            text: `Diametro: 5 – 120 mm<br/>Altezza: 5 – 140 mm<br/>Materiali: PP, ABS, PCTA, PE, PETG, PS, SAN, Alu, vetro, Surlyn`
          },
          companycert: {
            title: '<strong>Certificazioni</strong> azienda',
            text: 'DIN ISO 9001:2015'
          }
        },
        career: {
          meta: {
            title: 'Cosmelux - lavora con noi',
            description: ''
          },
          title: {
            small: 'candidati',
            text: 'Lavora con noi'
          },
        },
        rd: {
          meta: {
            title: 'Cosmelux - Ricerca e sviluppo',
            description: ''
          },
          title: {
            small: 'Cosmelux',
            text: 'Ricerca e sviluppo'
          },
          p1: `L'attenzione a tutti gli aspetti riguardanti la ricerca e lo sviluppo permette a Cosmelux di esprimersi con una vasta gamma di prodotti standard e di soluzioni on demand.<br/><br/>
              Il reparto di colorimetria/campionatura è in grado di soddisfare le esigenze dei ns. Clienti in fatto di tinte ed effetti speciali grazie all’utilizzo di una linea di produzione dedicata alla R&D.<br/><br/>
              Il supporto di <a href='http://metal3impianti.com/' target="_blank"><strong>Metal 3 Impianti</strong></a>, azienda specializzata nella progettazione e realizzazione di impianti di metallizzazione sotto vuoto, permette a Cosmelux di rimanere all’avanguardia nel suo settore.`,
          innovation: {
            title: 'Innovazione',
            p1: `L'innovazione dei processi produttivi e dei prodotti sono da sempre, per Cosmelux, il motore stesso della sua crescita.<br/>
                Più della metà del risultato di esercizio viene ogni anno reinvestita in ricerca e sviluppo.<br/><br/>
                <strong>Cosmelux</strong> ricerca e promuove la più stretta collaborazione con i propri Partners (clienti, fornitori, enti di ricerca locali ed internazionali, ecc) al fine di mantenere gli elevati standard di conoscenza tecnica e produttiva per i quali si è affermata sul mercato.<br/><br/>
                I nostri tecnici sono costantemente al lavoro per individuare nuovi prodotti ad alte prestazioni, in grado di soddisfare le esigenze sempre nuove dei Clienti e con l'obiettivo di trovare, per ciascuno di loro, la migliore soluzione possibile.`
          }

        },
        privacypolicy: {
          text : 'privacy policy',
          href : 'files/22078_51_472144_2730316_Informativa_a__Clienti_e_relativi_referenti_interni.pdf',
          meta: {
            title: 'Cosmelux - Privacy policy',
            description: ''
          },
          title: {
            small: 'cosmelux',
            text: 'Privacy policy'
          }
        },
        aboutus: {
          meta: {
            title: 'Cosmelux - metallizzazione e verniciatura U.V.',
            description: ''
          },
          title: {
            small: 'we are',
            text: 'Cosmelux'
          },
          p1: `<strong>Cosmelux</strong> è una società con sede a Carate Brianza controllata da Metal 3, creata nel febbraio 2002 ed operante nel settore della realizzazione di trattamenti di finitura. In particolare esegue la metallizzazione e la verniciatura U.V. per il mercato della cosmetica e della profumeria di alta gamma.<br/><br/>
              Gli impianti di verniciatura di cui dispone Cosmelux beneficiano di innovazioni tecniche forti di un'esperienza di 20 anni nel settore U.V. Sono stati concepiti nell'ottica di rispondere a richieste di verniciature particolari (con effetti tipo perlato, givrè, ecc.) che necessitano generalmente l'applicazione di diversi strati di vernice.<br/><br/>
              I nostri impianti di metallizzazione sono all’avanguardia nel settore di riferimento.`,
          p2: `Nel 2010 Cosmelux ha acquistato un nuovo impianto di verniciatura e metallizzazione in alto vuoto.<br/>
              Questo impianto ha la particolarità di realizzare l'operazione di metallizzazione in maniera completamente automatizzata.<br/>
              Unico nel suo genere e innovativo, questo impianto consente a Cosmelux di aumentare la capacità produttiva e di ridurre i costi di produzione.<br/>
              Questo nuovo investimento è stato studiato per rispondere alle nuove esigenze qualitative del mercato Mass Market e per offrire prezzi più competitivi ai clienti.<br/><br/>
              Nel 2014, Cosmelux ha acquistato un altro impianto automatico per potere rispondere alla domanda sempre più forte dei suoi clienti.<br/><br/>
              A partire del 2015, Cosmelux ha messo a punto con i suoi miglior partner un nuovo tipo di impianti più piccoli e più versatili con alta capacità produttiva. Questi impianti permettono di ridurre i tempi di cambio, di trattare qualunque tipologia di articolo, e di garantire un rapporto qualità prezzo eccezionale.`,
          p3: `Sono adatti sia alla verniciatura che alla metallizzazione garantendo un altissima flessibilità per rispondere tempestivamente a tutte le domande del mercato. Dal 2015, 4 di questi impianti sono stati installati con successo in Cosmelux.<br/><br/> 
              Competenza ed esperienza nel settore dei trattamenti di finitura U.V., flessibilità, importanti investimenti in tecnologia hanno reso possibile la continua evoluzione di Cosmelux.<br/><br/> 
              Dal 2007, Cosmelux registra una forte crescita strutturale e di presenza sul mercato nazionale e internazionale.<br/><br/>
              Grazie alla qualità dei suoi servizi e alla particolare attenzione al cliente, Cosmelux è diventata leader nel settore dei trattamenti superficiali U.V. a livello nazionale e riesce ad essere competitiva con i più grandi nomi del settore a livello mondiale.`,
          strategy: {
            title: 'Strategia',
            p1: `L'azienda ha realizzato un Sistema di Gestione della Qualità secondo la norma UNI EN ISO 9001:2015 e riconfermato il proprio impegno in tema di sostenibilità ambientale con le norme ISO 14001:2015 e ISO 14064-1:2019.`,
            p2: `La strategia è volta ad assicurare al cliente attraverso il Sistema aziendale Cosmelux s.r.l., il miglioramento continuo dell'organizzazione, dei processi e della Customer Satisfaction.`
          },
          bottom_banner: {
            title: 'Nuova linea produttiva "industria 4.0"'
          }
        },
        contacts: {
          meta: {
            title: 'referenze title',
            description: 'referenze description'
          },
          title: {
            small: 'i nostri',
            text: 'Contatti'
          },
          sidebar: {
            address: 'indirizzo',
            phone: 'telefono',
            socdata: 'dati societari'
          }
        },
        footer: {
          datas: '© 2019 COSMELUX - P.IVA / C.F. 03460330966. Tutti i diritti riservati.',
          disclaimer: `Obblighi informativi per le erogazioni pubbliche: gli aiuti di Stato e gli aiuti de minimis ricevuti dalla nostra impresa sono contenuti nel Registro nazionale degli aiuti di Stato di cui all’art. 52 della L. 234/2012” e consultabili al <a href='https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx' target="_blank"><strong>seguente link</strong></a> inserendo come chiave di ricerca nel campo CODICE FISCALE il codice fiscale della propria azienda`,
          contactbutton: 'contattaci'
        },
        navigation: {
            home : {
              text : 'Home',
              href : ''
            },
            about : {
                text : 'Chi siamo',
                href : 'cosmelux-srl'
            },
            uvlacquering : {
              text : 'Laccatura UV',
              href : 'laccatura-uv'
            },
            uvmetallization : {
              text : 'Metallizzazione UV',
              href : 'metallizzazione-uv'
            },
            rd : {
              text : 'R&D',
              href : 'ricerca-e-sviluppo'
            },
            realizations : {
              text : 'Realizzazioni',
              href : 'realizzazioni'
            },
            references : {
              text : 'Referenze',
              href : 'referenze'
            },
            contacts : {
              text : 'Contatti',
              href : 'contatti'
            },
            career : {
              text : 'Lavora con noi',
              href : 'lavora-con-noi'
            }
        }
      },
}

export default lang;