import React, {Component} from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import './App.css';
import './css/main.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './components/Homepage';
import UvLacquering from './components/UvLacquering';
import UvMetallization from './components/UvMetallization';
import Contacts from './components/ContactPage';
import References from './components/References';
import AboutUs from './components/AboutUs';
import ReD from './components/ReD';
import Realizations from './components/Realizations';
import Career from './components/Career';
import PrivacyPolicy from './components/PrivacyPolicy';
import strings from './langs/strings'



 



class App extends Component {

  constructor(state){
    super(state)
    this.state = {
      language : 'it'
    }

    this.handleLanguageSwitch = this.handleLanguageSwitch.bind(this)
  }

  componentDidMount(){

  }

  render(){



    return(

      <div className="App" id="app">
        <BrowserRouter>
          <Header sitelang={this.state.language} handleLanguageSwitch={this.handleLanguageSwitch}/>
          <Route exact path={`/`} render={(props) => <Homepage strings={strings} />} />
          <Route exact path={`/${this.state.language}`} render={(props) => <Homepage strings={strings} />} />
          <Route exact path={`/${this.state.language}/${strings.navigation.about.href}`} component={AboutUs} />
          <Route exact path={`/${this.state.language}/${strings.navigation.rd.href}`} component={ReD} />
          <Route exact path={`/${this.state.language}/${strings.navigation.uvlacquering.href}`} component={UvLacquering} />
          <Route exact path={`/${this.state.language}/${strings.navigation.uvmetallization.href}`} component={UvMetallization}/>
          <Route exact path={`/${this.state.language}/${strings.navigation.contacts.href}`} component={Contacts} />
          <Route exact path={`/${this.state.language}/${strings.navigation.references.href}`} component={References} />
          <Route exact path={`/${this.state.language}/${strings.navigation.realizations.href}`} component={Realizations} />
          <Route exact path={`/${this.state.language}/${strings.navigation.career.href}`} component={Career} />
          <Route exact path={`/${this.state.language}/${strings.privacypolicy.href}`} component={PrivacyPolicy} />
          <Footer sitelang={this.state.language} />
        </BrowserRouter>


      </div>  

    )
  }


  handleLanguageSwitch(newlang){
    strings.setLanguage(newlang)
    this.setState({
        language: newlang
    });
  }

}

export default App;
