import React, {Component} from 'react';
import Pageheader from './Pageheader';
import ReferencesList from './Reference'
import strings from '../langs/strings'


class References extends Component{



    render(){

        var refs = [
            {
                title: 'Acqua di Parma',
                image: 'acqua-di-parma.jpg',
                url: 'http://www.acquadiparma.it'
            },
            {
                title: 'Artistry',
                image: 'artistry.png',
                url: 'http://www.amway.com'
            },
            {
                title: 'AVON',
                image: 'avon.png',
                url: 'http://www.avon.it'
            },
            {
                title: 'Boots',
                image: 'boots.png',
                url: 'http://www.boots.com'
            },
            {
                title: 'Borsalino',
                image: 'borsalino.png',
                url: 'http://www.borsalino.com'
            },
            {
                title: 'Bourjois paris',
                image: 'bourjois-paris.jpg',
                url: 'https://www.bourjois.com/'
            },
            {
                title: 'Breil milano',
                image: 'breil-milano.jpg',
                url: 'https://www.breil.com/'
            },
            {
                title: 'parfum Cartier paris',
                image: 'parfum-cartier-paris.png',
                url: 'https://www.cartier.it/'
            },
            {
                title: 'Chanel logo',
                image: 'chanel.png',
                url: 'https://www.chanel.com/'
            },
            {
                title: 'Chopard',
                image: 'chopard.png',
                url: 'https://www.chopard.com/'
            },
            {
                title: 'Clarins',
                image: 'clarins.jpg',
                url: 'https://www.clarins.it/'
            },
            {
                title: 'Collistar',
                image: 'collistar.png',
                url: 'https://www.collistar.it/'
            },
            {
                title: 'Decleor paris',
                image: 'decleor-paris.png',
                url: 'https://www.decleor.it/'
            },
            {
                title: 'Dior',
                image: 'dior.png',
                url: 'https://www.dior.com/'
            },
            {
                title: 'Elizabeth Arden',
                image: 'elizabeth-arden.png',
                url: 'https://www.elizabetharden.it/'
            },
            {
                title: 'Estee Lauder',
                image: 'estee-lauder.jpg',
                url: 'https://www.elizabetharden.it/'
            },
            {
                title: 'Salvatore Ferragamo',
                image: 'salvatore-ferragamo.jpg',
                url: 'https://www.ferragamo.com/'
            },
            {
                title: 'Ferrari',
                image: 'ferrari.png',
                url: 'https://www.store.ferrari.com/'
            },
            {
                title: 'Givenchy',
                image: 'givenchy.jpg',
                url: 'https://www.givenchy.com/'
            },
            {
                title: 'Gucci',
                image: 'gucci.png',
                url: 'https://www.gucci.com/'
            },
            {
                title: 'Guerlain paris',
                image: 'guerlain-paris.png',
                url: 'https://www.guerlain.com/'
            },
            {
                title: 'Guinot paris',
                image: 'guinot-paris.png',
                url: 'http://it.guinot.com/'
            },
            {
                title: 'Helena Rubinstein',
                image: 'helena-rubinstein.png',
                url: 'http://www.helenarubinstein.com/'
            },
            {
                title: 'Hermes paris',
                image: 'hermes-paris.png',
                url: 'http://www.hermes.com/'
            },
            {
                title: 'Innoxa',
                image: 'innoxa.png',
                url: 'https://www.innoxa-cosmetics.com/'
            },
            {
                title: 'Jean Paul Gaultier',
                image: 'jean-paul-gaultier.jpg',
                url: 'https://www.jeanpaulgaultier.com/'
            },
            {
                title: 'John Richmond',
                image: 'john-richmond.png',
                url: 'https://www.johnrichmond.com/'
            },
            {
                title: 'Joop!',
                image: 'joop.png',
                url: 'https://www.joop.com/'
            },
            {
                title: 'Kenzo',
                image: 'kenzo.png',
                url: 'https://www.kenzoparfums.com/'
            },
            {
                title: 'Lancaster paris',
                image: 'lancaster-paris.jpg',
                url: 'https://www.lancaster.com/'
            },
            {
                title: 'Lancome paris',
                image: 'lancome-paris.svg',
                url: 'https://www.lancome.it/'
            },
            {
                title: 'Laura Mercier',
                image: 'laura-mercier.jpg',
                url: 'https://www.lancome.it/'
            },
            {
                title: 'Lolita Lempicka',
                image: 'lolita-lempicka.jpg',
                url: 'https://www.lolitalempicka.com/'
            },
            {
                title: 'loreal',
                image: 'loreal-paris.png',
                url: 'https://www.loreal.it/'
            },
            {
                title: 'max factor',
                image: 'max-factor.png',
                url: 'https://www.maxfactor.com/'
            },
            {
                title: 'Natura dermatology',
                image: 'natura-dermatology.jpg',
                url: 'https://www.naturadermatology.com/'
            },
            {
                title: 'Oriflame',
                image: 'oriflame.jpg',
                url: 'https://www.oriflame.com/'
            },
            {
                title: 'Pupa',
                image: 'pupa-milano.jpg',
                url: 'https://www.pupa.it/'
            },
            {
                title: 'Roberto Cavalli',
                image: 'roberto-cavalli.png',
                url: 'https://www.robertocavalli.com/'
            },
            {
                title: 'Thalgo',
                image: 'thalgo.jpg',
                url: 'https://www.thalgo.com/'
            },
            {
                title: 'Thierry Mugler',
                image: 'thierry-mugler.jpg',
                url: 'https://www.mugler.com/'
            },
            {
                title: 'Emanuel Ungaro',
                image: 'emanuel-ungaro.png',
                url: 'https://www.ungaro.com/'
            },
            {
                title: 'Van Cleef & Arpels',
                image: 'van-cleef-e-arpels.jpg',
                url: 'https://www.vancleefarpels.com/'
            },
            {
                title: 'Versace',
                image: 'versace.jpg',
                url: 'https://www.versace.com/'
            },
            {
                title: 'Victorias Secret',
                image: 'victorias-secret.png',
                url: 'https://www.victoriassecret.com/'
            },
            {
                title: 'Victorinox',
                image: 'victorinox.png',
                url: 'https://www.victorinox.com/'
            },
            {
                title: 'Yves Rocher',
                image: 'yves-rocher.png',
                url: 'http://www.yves-rocher.com/'
            },
            {
                title: 'Yves Saint Laurent',
                image: 'yves-saint-laurent.png',
                url: 'http://www.ysl.com/'
            },
            {
                title: 'BIC',
                image: 'bic-logo.png',
                url: 'https://it.bicworld.com/'
            }
        ]


        return(
            <React.Fragment>
            <Pageheader lang="it" small={strings.references.title.small} title={strings.references.title.text} bgimage='references-cover.jpg'/>

            <section className="my-5">

                <div className="container">
                    <div className="row">
                        <ReferencesList datas={refs} dir='references'/>
                    </div>
                </div>

            </section>


            </React.Fragment>
        )
    }
}

export default References;