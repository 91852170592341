import LocalizedStrings from 'react-localization'
import it from './it'
import en from './en'
import fr from './fr'
import de from './de'

const languages = {...it, ...en , ...fr, ...de}

const strings = new LocalizedStrings(languages);

export default strings;