import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%'
};

export class MapContainer extends Component {
  render() {
    return (
        <Map
            google={this.props.google}
            zoom={16}
            style={mapStyles}
            center={{
            lat: 45.665554,
            lng: 9.2366424
            }}
        >
        <Marker
          name={'Cosmelux s.r.l.'}
        />
        </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyArid6NhqlLfgkNZaZH7JQAcLXojlsp_KU'
})(MapContainer);