import React, {Component} from 'react';
import Pageheader from './Pageheader';
import strings from '../langs/strings'
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from 'react-helmet'



class PrivacyPolicy extends Component{


    render(){
        return(
            <React.Fragment>

            <Helmet>
                <title>{strings.privacypolicy.meta.title}</title>
                <meta name="description" content={strings.privacypolicy.meta.description} />
            </Helmet>

            <Pageheader lang="it" small={strings.privacypolicy.title.small} title={strings.privacypolicy.title.text} list={strings.privacypolicy.title.list} bgimage='work-with-us-cover.jpg'/>
            <section className="py-5 grey-circle-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-left" id="privacy-container"></div>
                    </div>
                </div>
            </section>
            </React.Fragment>
        )
    }

    componentDidMount(){
        fetch('https://www.iubenda.com/api/privacy-policy/37658602/no-markup')
        .then(function(response) {
          return response.json();
        })
        .then(function(myJson) {

            var privacy_container = document.getElementById('privacy-container');
            var privacy_content = document.createElement("div");
            privacy_content.innerHTML = myJson.content;
            privacy_container.appendChild(privacy_content);
        });
    }

    
}

export default PrivacyPolicy;