import React, {Component} from 'react'
import Pageheader from './Pageheader'
import strings from '../langs/strings'
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from "react-helmet";

export default class AboutUs extends Component{
    render(){
        return(
            <React.Fragment>

            <Helmet>
                <title>{strings.uvlacquering.meta.title}</title>
                <meta name="description" content={strings.uvlacquering.meta.description} />
            </Helmet>

                <Pageheader lang="it" small={strings.aboutus.title.small} title={strings.aboutus.title.text} bgpos='bottom center' bgimage='about-cover.jpg'/>
                <section className="grey-shot-light">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-7 text-justify">
                                <p>{ReactHtmlParser(strings.aboutus.p1)}</p>
                            </div>
                            <div className="col-lg-5">
                                <img alt='cosmelux-company' src="/images/about-cosmelux-1.jpg" className="w-100"/>
                                <img alt='cosmelux-company' src="/images/about-cosmelux-2.jpg" className="w-100 mt-2"/>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-6 text-justify">
                            <p>{ReactHtmlParser(strings.aboutus.p2)}</p>
                            </div>
                            <div className="col-lg-6 text-justify">
                            <p>{ReactHtmlParser(strings.aboutus.p3)}</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="bg light-grey py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center"> 
                                <h5 className="text-uppercase m-0 d-flex align-items-center">
                                    <div className="icon-square certificate mr-3"></div>
                                    {strings.aboutus.bottom_banner.title}
                                </h5>
                            </div>
                            <div className="col-lg-6 ">
                                <a href="/images/certificazione.jpg" data-toggle="lightbox" data-title={strings.aboutus.bottom_banner.title}>
                                    <img src="/images/certificazione_small.jpg" alt="" className="w-25"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="py-5 grey-circle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="signature title mb-5 text-left">{strings.aboutus.strategy.title}</h1>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-justify">{strings.aboutus.strategy.p1}</p>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-justify">{strings.aboutus.strategy.p2}</p>
                        </div>
                    </div>
                </div>
            </section>
            </React.Fragment>
        )
    }

    componentDidMount(){

    }


}