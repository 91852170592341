import React, {Component} from 'react'

export default class Reference extends Component{

    constructor(props){
        super(props)
    }

    mapReferences = (arr,key) => {
        let bgimage = `url('/images/${this.props.dir+'/'+arr.image}')`;
        return <div key={key} className="col-lg-2" ><a href={arr.url} target="_blank" rel="noopener noreferrer" style={{backgroundImage : bgimage}} className="d-block squared reference"></a></div>
    }

    render(){
        return(
            <React.Fragment>
            {this.props.datas.map(this.mapReferences)}
            </React.Fragment>

        )
    }
}