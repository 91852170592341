import React, {Component} from 'react'
import Pageheader from './Pageheader'
import strings from '../langs/strings'
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from "react-helmet";

export default class ReD extends Component{
    render(){
        return(
            <React.Fragment>

            <Helmet>
                <title>{strings.rd.meta.title}</title>
                <meta name="description" content={strings.rd.meta.description} />
            </Helmet>

                <Pageheader lang="it" small={strings.rd.title.small} title={strings.rd.title.text} bgpos='bottom center' bgimage='rd-cover.jpg'/>
                <section className="grey-shot-light">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-7 text-justify">
                                <p>{ReactHtmlParser(strings.rd.p1)}</p>
                            </div>
                            <div className="col-lg-5">
                                <img alt='cosmelux-company' src="/images/ReD_colors.png" className="w-100"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5 grey-circle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="signature title mb-5 text-left">{strings.rd.innovation.title}</h1>
                        </div>
                        <div className="col-lg-6">
                            <img alt='cosmelux-company' src="/images/rd2.jpg" className="w-50"/>
                            <img alt='cosmelux-company' src="/images/rd3.jpg" className="w-50"/>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-justify">{ReactHtmlParser(strings.rd.innovation.p1)}</p>
                        </div>
                    </div>
                </div>
            </section>
            </React.Fragment>
        )
    }

    componentDidMount(){
    }
}