let lang = {
  en: {
      slider: {
        captionbig : 'Perfection.',
        captionsmall : 'Our <span>added value</span>',
        list : ['lipstick','mascara','skincare']
      },
      homepage: {
        meta: {
          title: 'referenze title',
          description: 'referenze description'
        },
        perfectfinishing: {
          title: 'Perfect Finishing.',
          description: `Excellent chemical and mechanical resistance.<br/><br/>
                        Infinity of <strong>colors and effects</strong>, respecting the environment and guaranteeing a quality service and <strong>personalized</strong> aesthetics with possible subsequent detail in tampography, serigraphy, hot stamping or laser.`
        },
        icons:{
          magicwand: {
            title: 'surface pre-treatment',
            description: 'Cleaning in a controlled environment with flaming, ionization and brushing'
          },            
          star: {
            title: 'production capacity',
            description: 'For pieces of standard size: 400,000 pieces per day.'
          },            
          diamond: {
            title: 'preocious surfaces',
            description: 'which can be decorated with pad printing, screen printing, hot stamping or laser.'
          },            
          bucket: {
            title: 'customization',
            description: 'Possibility of obtaining an infinity of colors and "effects".'
          }
        },
        carousel: [
          {
            title: 'Processing',
            description: 'Gli impianti di verniciatura dedicati al settore della cosmetica sono caratterizzati da pre-trattamento di pulizia con fiammatura, ionizzazione, spazzolatura e applicazione di prodotti vernicianti in ambiente controllato (umidità, temperatura, filtrazione aria) con postazioni multiple di verniciatura, asciugatura in forni ad aria forzata con controllo di temperatura, e infine polimerizzazione con lampade a UV. Successivamente sarà possibile decorare il prodotto in tampografia, serigrafia, stampa a caldo o laser.'
          },
          {
            title: 'Processing',
            description: 'The painting plants dedicated to the cosmetic sector are characterized by cleaning pre-treatment with flaming, ionization, brushing and application of coating products in a controlled environment (humidity, temperature, air filtration) with multiple painting stations, drying in air ovens forced with temperature control, and finally polymerization with UV lamps. Subsequently it will be possible to decorate the product in pad printing, screen printing, hot printing or laser.'
          }
        ],
      },
      contactform: {
        namelastname: 'name & lastname*',
        company: 'company name',
        emailaddress: 'e-mail address*',
        phone: 'phone',
        message: 'message*',
        privacy: `I declare that I have read the {0}*`,
        privacyu: 'privacy policy',
        newsletter: 'I therefore consent to the processing of my personal data by Cosmelux s.r.l for marketing purposes.',
        requiredfields: 'Fields marked with * are mandatory',
        sendbutton: 'send',
        thx: `Dear {0}, thank's for contacting us. We wll reply soon to the e-mail address {1}`,
        error: 'Some data are incomplete or missing'
      },
      careerform: {
        firstname: 'first name*',
        lastname: 'last name*',
        emailaddress: 'e-mail address*',
        cvupload: 'upload your CV*',
        phone: 'phone*',
        message: 'cover letter*',
        privacy: `I declare that I have read the {0}*`,
        privacyu: 'privacy policy',
        newsletter: 'I therefore consent to the processing of my personal data by Cosmelux s.r.l for marketing purposes.',
        requiredfields: 'Fields marked with * are mandatory',
        sendbutton: 'send',
        thx: `Dear {0}, thank's for contacting us. We wll reply soon to the e-mail address {1}`,
        error: 'Some data are incomplete or missing'
      },
      references: {
        meta: {
          title: 'referenze title',
          description: 'referenze description'
        },
        title: {
          small:'our',
          text:'References'
        }
      },
      realizations: {
        meta: {
          title: 'referenze title',
          description: 'referenze description'
        },
        title: {
          small:'cosmelux',
          text:'Realizations'
        },
        filterbar: 'Filter by',
        filterbuttons: ['all','lipstick','mascara','miniatures','perfumes','skin care','nail varnish','pens']
      },
      uvmetallization: {
        meta: {
          title: 'referenze title',
          description: 'referenze description'
        },
        title: {
          small: 'services',
          text: 'UV metallization',
          list: ['perfumes','creams']
        },
        intro: {
          text : `The 4 <strong>metallization vacuum vessel</strong> (3 PVD e 1 SPUTTERING) have been implemented to be able to guarantee the efficiency and the maximal quality keeping an excellent value for money.<br/><br/>
                  The last generation of metalizing by "Sputtering" machines has allowed Cosmelux to unite in a completely automatic way the varnishing and metalizing processes.`
        },
        advantages: {
          title: 'Advantages',
          list: [
            'A high quality of resistance to conditioned products (Creams, perfumes, lotions…)',
            'A high mechanical resistance keeping a softness allowing to guarantee the aptitudes to the detent of the assembled pieces',
            'A minimal deformation of worked pieces, compared with a traditional metallization, because the process does not overheat the pieces',
            'A very high esthetic quality, similar to the electrotyping',
            'A capacity to obtain an infinity of colors and special effects, impossible with the electrotyping',
            'UV Metalizing allows the depositing of many structured varnish coats, in order to obtain special effects (magnifying glass effect, gradation effect, old-looking effect…)',
            'UV varnishes can be then decorated by tampography, screen process, hot stamping or laser',
            'Today, UV varnishes meet environment requests and do not hold heavy metal'
          ]
        },
        productivecapacity: {
          title: '<strong>Daily productive</strong> capacity',
          text: 'Production Capacity for standard size pieces: 500,000 pieces per day.'
        },
        piecestype: {
          title: 'Tipo di <strong>pezzi trattabili</strong>',
          text: 'Diameter: 5 – 120 mm<br/>Height: 5 – 140 mm<br/>Materials: PP, ABS, PCTA, PE, PETG, PS, SAN, Alu, VERRE,'
        },
        companycert: {
          title: 'Compayn <strong>Certification</strong>',
          text: 'DIN ISO 9001:2008'
        },
        termevapimplant: {
          title: {
            small: 'PVD',
            text: 'Physical vapor deposition'
          },
          description: `Today, the utilization of PVD metallization machines is the most frequent method in the sector of metallization linked with the surface treatments that have to improve the esthetic aspects. These machines are simple to use and allow to metalize pieces of complexes shapes.<br/><br/>
          The source that will allows the deposition of vacuum aluminium are tungsten resistances put at the centre of the metallizing vacuum vessel. At the centre of every resistance the operator put inside an aluminium strand.<br/><br/>
          The pieces to be metalized are put on satellites on the edge of these resistances. Then those satellites are put in rotation during the aluminium vacuum step.<br/><br/>
          It is then necessary to reach a vacuum field (superior to 1x10-4 mbar) to be able to evaporate perfectly the aluminium.<br/><br/>
          When this level of vacuum field is reached, the tungsten resistances are the "heated" until the step of glow, the aluminium comes to fusion. The vacuum aluminium propagates inside the metalization vacuum field and cover the pieces in rotational movement by an vapor effect.`
        },
        sputtering: {
          title: {
            small: 'PVD',
            text: 'Sputtering'
          },
          description: `<strong>Modern</strong>, <strong>fast</strong> and <strong>economic</strong> metal deposition system originally used only on substrates with flat surfaces, today instead it is increasingly used also on three-dimensional objects, even if with simple geometries.<br/><br/>
                        The source of deposition is a magnetic cathode (Magnétron). An aluminium target is fixed on this cathode.<br/><br/>
                        The group is positioned on the way of the vacuum chamber. The pieces are in rotation in front of the target (in order to uniform the deposition of aluminium).<br/><br/>
                        After achievement of a vacuum field, argon is introduced in the chamber (chosen for its high atomic mass and for its chemical inertness) to be able to reach a residual pressure of 3x10-3 mbar. The cathode is then polarized to generate the erosion of the target into aluminium.<br/><br/>
                        The aluminium atoms propagate then by condensation effect on the pieces in rotation.`
        }
      },
      uvlacquering: {
        meta: {
          title: 'referenze title',
          description: 'referenze description'
        },
        title: {
          small: 'services',
          text: 'UV Lacquering',
          list: ['perfumes','creams']
        },
        intro: {
          text : `Both <strong>varnishing/lacquering lines</strong> have been internally implemented and have a capacity of 120.000 pieces by a 8 hours team.
          These lacquering lines dedicated to the cosmetic sector are completely pressurized.<br/>
          The pieces to work are firstly cleaned (brushing and blowing of ionized air), then flamed. Then the varnish coats are pulverized in the controlled ambient cabines. The solvents are then vaporized by Infra Rouge or Warm Pulsated Air (and burnt in a thermal burner post). Finally the varnish is reticulated by UV radiation.`
        },
        advantages: {
          title: 'Advantages',
          list: [
            'A high quality of resistance to conditioned products (Creams, perfumes, lotions…)',
            'A high mechanical resistance keeping a softness allowing to guarantee the aptitudes to the detent of the assembled pieces',
            'A minimal deformation of worked pieces, compared with a traditional metallization, because the process does not overheat the pieces',
            'A very high esthetic quality, similar to the electrotyping',
            'A capacity to obtain an infinity of colors and special effects, impossible with the electrotyping',
            'UV lacquering allows the depositing of many structured varnish coats, in order to obtain special effects (magnifying glass effect, gradation effect, old-looking effect…)',
            'UV varnishes can be then decorated by tampography, screen process, hot stamping or laser',
            'Today, UV varnishes meet environment requests and do not hold heavy metal.'
          ]
        },
        productivecapacity: {
          title: '<strong>Daily productive</strong> capacity',
          text: 'Production Capacity for standard size pieces: 400,000 pieces per day.'
        },
        piecestype: {
          title: 'Typology of <strong>pieces to work</strong>',
          text: 'Diameter: 5 – 120 mm<br/>Altezza: 5 – 140 mm<br/>Materials: PP, ABS, PCTA, PE, PETG, PS, SAN, Alu, vetro, Surlyn'
        },
        companycert: {
          title: 'Company <strong>certifications</strong>',
          text: 'DIN ISO 9001:2008'
        }
      },
      career: {
        meta: {
          title: 'Cosmelux - Work with us',
          description: ''
        },
        title: {
          small: 'send your CV',
          text: 'Work with us'
        },
      },
      rd: {
        meta: {
          title: 'Cosmelux - Research and Development',
          description: ''
        },
        title: {
          small: 'Cosmelux',
          text: 'Research and Development'
        },
        p1: `This will of innovation and of adaptation to the market demand allows Cosmelux to obtain numerous standards solutions and numerous "On Demand" solutions.<br/><br/>
            Everyday a production line is used to develop new colors and special effects to satisfy the cosmetics design demands.<br/><br/>
            The support of <a href='http://metal3impianti.com/' target="_blank"><strong>Metal 3 Impianti</strong></a>, Company specialized in the conception and realization of vacuum metallization machines allows Cosmelux to be and remain on the cutting edge of its sector.`,
        innovation: {
          title: 'Innovation',
          p1: `The permanent improving of production process and innovation have always been at the origins of Cosmelux expansion.<br/>
              More than the half of our results are systematically invested every year in Research and Development.<br/><br/>
              <strong>Cosmelux</strong> and promotes its very tight collaboration with Partners (customers, suppliers, universities and research centres, etc…) in order to maintain at the highest level the technical knowledge that distinguishes the Company from its direct competitors.<br/><br/>
              Our qualified technicians are constantly looking for new products and process on the cutting edge of technology to be able to satisfy our customers requests, always. The purpose is to propose the best ever solution for them.`
        }

      },
        privacypolicy: {
          text : 'privacy policy',
          href : '22078_51_472144_2730316_Informativa_a__Clienti_e_relativi_referenti_interni in inglese.pdf',
          meta: {
            title: 'Cosmelux - Privacy policy',
            description: ''
          },
          title: {
            small: 'cosmelux',
            text: 'Privacy policy'
          }
        },
      aboutus: {
        meta: {
          title: 'referenze title',
          description: 'referenze description'
        },
        title: {
          small: 'we are',
          text: 'Cosmelux'
        },
        p1: `Cosmelux, subsidiary of METAL 3 Company, was created in March 2002.<br/><br/>
            On the cutting edge of technology, Cosmelux work tool has been thought to provide UV metalized or lacquered items destinated to the high range cosmetic and fragrance market.The lacquering and varnishing lines have been created in order to be able to answer our customers complex requests in terms of aspects or new effects that generally need the application of many varnish or lack coat.<br/><br/>
            The two lines, are completely pressurized, air-conditioned with a decontamination type «salle blanche».<br/><br/>
            Our metallization systems are at the forefront in the reference sector.`,
        p2: `Nel 2010 Cosmelux ha acquistato un nuovo impianto di verniciatura e metallizzazione in alto vuoto.<br/>
            This machine allows to accomplish the operation of metallization without cycle interruption, in a completely automatic way.<br/>
            Exceptional in its gender and very innovative , this line allowed Cosmelux to triplicate its production capacity and to reduce significantly its costs.<br/>`,
        p3: `Since 2007, thanks to the service quality of the Company, thanks to its reactivity and to the utmost importance given to its customers satisfaction, Cosmelux is in permanent development and is positioned as an incontrovertible actor within its sector and at the European level.`,
        strategy: {
          title: 'Strategy',
          p1: `The company has created a Quality Management System according to the UNI EN ISO 9001:2015 standard and reconfirmed its commitment to environmental sustainability with the ISO 14001:2015 and ISO 14064-1:2019 standards.`,
          p2: `his daily performance is possible thanks to a modern industrial tool , perfectly controlled processes, a trained workforce and a rigorous and performed quality system (according to the UNI EN ISO 9001:2015 norm).`
        },
        bottom_banner: {
          title: 'New production line "industria 4.0"'
        }
      },
      contacts: {
        meta: {
          title: 'referenze title',
          description: 'referenze description'
        },
        title: {
          small: 'Our',
          text: 'Contacts'
        },
        sidebar: {
          address: 'address',
          phone: 'phone',
          socdata: 'societary data'
        }
      },
      footer: {
        datas: '© 2019 COSMELUX - P.IVA / C.F. 03460330966. All rights reserved.',
        disclaimer: '',
        contactbutton: 'contact us'
      },
      navigation: {
          home : {
            text : 'Home',
            href : ''
          },
          about : {
              text : 'About us',
              href : 'cosmelux-srl'
          },
          uvlacquering : {
            text : 'UV lacquering',
            href : 'uv-lacquering'
          },
          uvmetallization : {
            text : 'UV metallization',
            href : 'uv-metallization'
          },
          rd : {
            text : 'R&D',
            href : 'research-and-development'
          },
          realizations : {
            text : 'Realizations',
            href : 'realizations'
          },
          references : {
            text : 'References',
            href : 'references'
          },
          contacts : {
            text : 'Contacts',
            href : 'contact-us'
          },
          career : {
            text : 'Work with us',
            href : 'work-with-us'
          }
      }
    },
}

export default lang;