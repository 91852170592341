import React, {Component} from 'react';
import Pageheader from './Pageheader';
import FilterBar from './Filterbar'
import Realization from './Realization';
import strings from '../langs/strings'


/*const realizations = [
    {
        image: '3tappi-G.jpg',
        filterid: 1,
        description: 'Metallizzazione UV resistente allo smalto'
    },
    {
        image: 'base-mascara-oro-G.jpg',
        filterid: 2,
        description: 'Metallizzazione UV oro decorabile in stampa a caldo'
    },
    {
        image: 'chopard-G.jpg',
        filterid: 3,
        description: 'Metallizzazione UV rosa con alta resistenza al profumo'
    },
    {
        image: 'ferragamo-incanto-G.jpg',
        filterid: 4,
        description: 'Metallizzazione UV cromo tridimensionale'
    },
    {
        image: 'tappo-perlato-G.jpg',
        filterid: 1,
        description: 'Metallizzazione UV con pigmento madre perlato'
    },
    {
        image: 'aura-by-swarovsky-G.jpg',
        filterid: 5,
        description: 'Metallizzazione UV argento lucido con alte tenute chimiche e meccaniche'
    },
    {
        image: 'cavalli-G.jpg',
        filterid: 3,
        description: 'Metallizzazione UV oro con alta resistenza al profumo'
    },
    {
        image: 'dior-benefit-G.jpg',
        filterid: 2,
        description: 'Metallizzazione UV canna di fucile con doppia serigrafia'
    },
    {
        image: 'tappo-sfumato-G.jpg',
        filterid: 1,
        description: 'Metallizzazione UV sfumata con alta resistenza allo sfregamento'
    },
    {
        image: 'gucci-nero-G.jpg',
        filterid: 3,
        description: 'Metallizzazione UV oro con alta resistenza al profumo'
    },
    {
        image: 'givenchy-G.jpg',
        filterid: 5,
        description: 'Metallizzazione UV rosa tridimensionale'
    },
    {
        image: 'loreal-accord-parfait-G.jpg',
        filterid: 5,
        description: 'Metallizzazione UV argento lucido flessibile'
    },
    {
        image: 'ferragamo-signorina-G.jpg',
        filterid: 4,
        description: 'Metallizzazione UV rame tridimensionale'
    },
    {
        image: 'ferrari-passion-G.jpg',
        filterid: 4,
        description: 'Metallizzazione UV argento bianco decorabile con getto di inchiostro'
    },
    {
        image: 'dior-extase-G.jpg',
        filterid: 2,
        description: 'Metallizzazione UV oro decorabile in serigrafia'
    },
    {
        image: 'smalto-dior-G.jpg',
        filterid: 1,
        description: 'Laccatura UV trasparente con alte resistenze chimiche e meccaniche'
    },
    {
        image: 'gucci-oro-G.jpg',
        filterid: 3,
        description: 'Metallizzazione UV oro con alta resistenza al profumo'
    },
    {
        image: 'loreal-lumi-magic-G.jpg',
        filterid: 5,
        description: 'Metallizzazione UV rame flessibile'
    },
    {
        image: 'oriflame-volume-G.jpg',
        filterid: 4,
        description: 'Metallizzazione UV argento lucido'
    },
    {
        image: 'oriflame-enigma-G.jpg',
        filterid: 4,
        description: 'Metallizzazione UV rosa tridimensionale'
    },
    {
        image: 'collistar-G.jpg',
        filterid: 1,
        description: 'Metallizzazione UV oro con alta resistenza allo sfregamento'
    },
    {
        image: 'dior-iconic-G.jpg',
        filterid: 2,
        description: 'Metallizzazione UV argento lucido decorabile in serigrafia'
    },
    {
        image: 'wolfang-joop-G.jpg',
        filterid: 3,
        description: 'Metallizzazione UV argento con alta resistenza al profumo'
    },
    {
        image: 'laura-mercier-G.jpg',
        filterid: 2,
        description: 'Laccatura con pigmento'
    },
    {
        image: 'mascara-lungo-G.jpg',
        filterid: 2,
        description: 'Metallizzazione UV oro con alta resistenza allo sfregamento'
    },
    {
        image: 'chopard-wish-G.jpg',
        filterid: 3,
        description: 'Metallizzazione UV argento con alta resistenza al profumo'
    },
    {
        image: 'viola-G.jpg',
        filterid: 4,
        description: 'Metallizzazione UV Parme con alta resistenza al profumo e ottima tenuta del colore nel tempo'
    },
    {
        image: 'starlette-G.jpg',
        filterid: 2,
        description: 'Metallizzazione UV oro decorabile in serigrafia'
    },
    {
        image: 'thalgo-G.jpg',
        filterid: 5,
        description: 'Metallizzazione UV argento lucido decorabile in serigrafia'
    },
    {
        image: 'victorinox-swiss-army-G.jpg',
        filterid: 4,
        description: 'Metallizzazione UV cromo satinato'
    },
    {
        image: 'yves-rocher-G.jpg',
        filterid: 5,
        description: 'Metallizzazione UV oro decorabile in tampografia'
    }
];*/


const realizations = [
    {
        image: 'tiffany_1.jpg',
        filterid: 4,
        description: ''
    },
    {
        image: 'tiffany_2.jpg',
        filterid: 4,
        description: ''
    },
    {
        image: 'paco_rabanne_olympea.jpg',
        filterid: 4,
        description: ''
    },
    {
        image: 'profumo.jpg',
        filterid: 4,
        description: ''
    },
    {
        image: 'lancome_absolue.jpg',
        filterid: 5,
        description: ''
    },
    {
        image: 'cha-cha-tint-benefit.jpg',
        filterid: 5,
        description: ''
    },
    {
        image: 'givenchy_liner_disturbia.jpg',
        filterid: 2,
        description: ''
    },
    {
        image: 'bambi_eye.jpg',
        filterid: 2,
        description: ''
    },
    {
        image: 'mascara_fenty_beauty.jpg',
        filterid: 2,
        description: ''
    },
    {
        image: 'liquid_lip_stain_yves_saint_lauren.jpg',
        filterid: 1,
        description: ''
    },
    {
        image: 'lip_glow_dior.jpg',
        filterid: 1,
        description: ''
    },
    {
        image: 'maybelline_falses_lash_lift.jpg',
        filterid: 2,
        description: ''
    },
    {
        image: 'maybelline_big_shot.jpg',
        filterid: 2,
        description: ''
    },
    {
        image: 'miniature-1.jpg',
        filterid: 3,
        description: ''
    },
    {
        image: 'miniature-2.jpg',
        filterid: 3,
        description: ''
    },
    {
        image: 'miniature-3.jpg',
        filterid: 3,
        description: ''
    },
    {
        image: 'alfaparf-reparative-mask.jpg',
        filterid: 5,
        description: ''
    },
    {
        image: 'smalti-1.jpg',
        filterid: 6,
        description: ''
    },
    {
        image: 'smalti-2.jpg',
        filterid: 6,
        description: ''
    },
    {
        image: 'smalti-3.jpg',
        filterid: 6,
        description: ''
    },
    {
        image: 'smalti-4.jpg',
        filterid: 6,
        description: ''
    },
    {
        image: 'bic-1.jpg',
        filterid: 7,
        description: ''
    },
    {
        image: 'bic-2.jpg',
        filterid: 7,
        description: ''
    }
];


const filterlist = [
    {
        title: strings.realizations.filterbuttons[0],
        filterid: 0
    },
    {
        title: strings.realizations.filterbuttons[1],
        filterid: 1
    },
    {
        title: strings.realizations.filterbuttons[2],
        filterid: 2
    },
    {
        title: strings.realizations.filterbuttons[4],
        filterid: 4
    },
    {
        title: strings.realizations.filterbuttons[5],
        filterid: 5
    },
    {
        title: strings.realizations.filterbuttons[6],
        filterid: 6
    },
    {
        title: strings.realizations.filterbuttons[7],
        filterid: 7
    },
    {
        title: strings.realizations.filterbuttons[3],
        filterid: 3
    }
];

class Realizations extends Component{

    constructor(state,props){
        super(state,realizations)

        this.state = {
            filtered : 0
        }
    }

    render(){

        return(
            <React.Fragment>
            <Pageheader lang="it" small={strings.realizations.title.small} title={strings.realizations.title.text} bgimage='realizations-cover.jpg'/>

            <section className="my-5">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            <FilterBar filterlist={filterlist} clickaction={this.handleFilterClick} realizations={realizations}/>
                        </div>
                        <div className="col-lg-12">

                                <Realization dir='realizations' activefilter={this.state.filtered} datas={realizations}/>

                        </div>
                    </div>
                </div>

            </section>


            </React.Fragment>
        )
    }

    
    handleFilterClick = (e) =>{

        let activefilter = parseInt(e.target.getAttribute('filter'));

        return this.setState({
            filtered : activefilter
        })
    }

}

export default Realizations;