import React, {Component} from 'react';
import Navigation from './Navigation';


class Header extends Component{

    constructor(props){

        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);

        this.state = {
            visible: false,
            language : this.props.sitelang
        };
    }

    callbackFunction = (childData) => {
        this.setState({visible: childData})
    }

    render(){
        return(
            <React.Fragment>
                <div id="menu-overlay" onClick={this.toggleMenu} ></div>

                <header className="d-flex align-items-stretch">
                    <div className="collapsed">
                        <div className="logotype mb-2"></div>
                        <div className="menu-icon mt-2" onClick={this.toggleMenu} ></div>
                        <Navigation parentCallback = {this.callbackFunction} sitelang={this.props.sitelang} handleLanguageSwitch={this.props.handleLanguageSwitch} visible={this.state.visible} />
                    </div>

                </header>
            </React.Fragment>
        )
    }

    toggleMenu(e){

        this.setState(()=>{
            return {visible : !this.state.visible}
        })
    }
}


export default Header;