import React, {Component} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export default class Realization extends Component{

    constructor(state,props){
        super(state,props)
    }

    render(){

        const items = this.props.datas.map((arr,key) => {
            let bgimage = `url('/images/${this.props.dir+'/'+arr.image}')`;
            let tpl = <div key={key} data-filter={arr.filterid} className="col-lg-3 py-3" ><div className="squared" style={{backgroundImage : bgimage}}></div></div>
    
            if(parseInt(arr.filterid) === parseInt(this.props.activefilter)){
                return tpl
            } else if(parseInt(this.props.activefilter) === 0){
                return tpl
            }
      
        })

        return(
            <React.Fragment>
            <ReactCSSTransitionGroup c
                component="div" 
                className="row"
                transitionName="fade"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnter={200}
                transitionLeave={false}
            >
                {items}
            </ReactCSSTransitionGroup>
                
            </React.Fragment>

        )
    }
}