import React, {Component} from 'react';
import strings from './../../langs/strings'
import ReactHtmlParser from 'react-html-parser';

class Mainslider extends Component{

    componentDidMount(){

        const $ = window.jQuery;   

        $("#revslider").revolution({
            delay: 6000,
            sliderLayout: 'auto',
            responsiveLevels: [1240, 1024, 778, 480],
            gridwidth:[1240, 1024, 778, 480],
            gridheight:[600, 768, 600, 720],
            navigation: {
                bullets: {
 
                    enable: true,
                    style: 'hesperiden',
                    tmp: '',
                    direction: 'horizontal',
                    rtl: false,

                    container: 'slider',
                    h_align: 'right',
                    v_align: 'top',
                    h_offset: 0,
                    v_offset: 20,
                    space: 5,
             
                    hide_onleave: false,
                    hide_onmobile: false,
                }
            },
            autoplay: true
        }); 
    }

    render(){
        return(
            <section>
            <div className="rev_slider_wrapper">
                <div id="revslider" className="rev_slider" data-version="5.0">
                    <ul>
                        <li data-transition="fade" data-slotamount="default" data-easein="default" data-easeout="default" data-masterspeed="default" data-link="slide" data-rotate="0" data-title="">
                            <img src="/images/slider/slider-main-bg.jpg"  alt=""  width="1920" height="1080" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="2" data-bgcolor="#66adca" className="rev-slidebg"/>

    
                            <div className="tp-caption signature"
                            data-x="['center','left','left','center']"
                            data-hoffset="['-200','60','35','0']"
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['0','0','0','0']"
                            data-fontsize="['140','70','60','100']"
                            data-letterspacing="['5','5','5','2']"
                            data-lineheight="['130','130','130','100']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-transform_idle="o:1;"
    
                            data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:1.5;sY:1.5;skX:0;skY:0;opacity:0;s:2000;e:Power3.easeInOut;"
                            data-transform_out="y:[100%];s:1000;s:1000;"
    
                            data-start="0"
                            data-splitin="none"
                            data-splitout="none"
                            data-responsive_offset="on">
                                {this.props.strings.captionbig}
                            </div>

                            <div className="tp-caption text"
                            data-x="['center','left','left','center']"
                            data-hoffset="['200','60','35','0']"
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['0','0','0','0']"
                            data-fontsize="['50','70','60','30']"
                            data-letterspacing="['0','5','5','2']"
                            data-lineheight="['normal','130','130','180']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-transform_idle="o:1;"
    
                            data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:1.5;sY:1.5;skX:0;skY:0;opacity:0;s:2000;e:Power3.easeInOut;"
                            data-transform_out="y:[100%];s:1000;s:1000;"
    
                            data-start="0"
                            data-splitin="none"
                            data-splitout="none"
                            data-responsive_offset="on">
                                {ReactHtmlParser(strings.slider.captionsmall)}
                                <ul className="list text-uppercase">
                                    {strings.slider.list.map((item, key) => {
                                        return <li key={key}>{item}</li>
                                    })}
                                </ul>
                            </div>
    
                        </li>

                    </ul>
                </div>
            </div>
            </section>
        )
    }
}

export default Mainslider;