import React from 'react';

function Textwithimage(props){
    const iconpath = '../images/icons/';
    return(
        <div className="col-lg-3 col-sm-12 text-with-icon my-2 my-md-0">
            <span className={"icon " + props.icon} style={{backgroundImage : `url('${iconpath+props.icon}.svg')`}}></span>
            <h6 className="text-uppercase">{props.title}</h6>
            <p className="m-0">{props.description}</p>
        </div>
    );
}

export default Textwithimage;