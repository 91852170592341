import React, {Component} from 'react';
import strings from '../langs/strings'

class FilterBar extends Component{
    constructor(props){
        super(props)

    }

    mapFilter = (arr,key) => {
        return <button  className="col w-b text-uppercase py-2" onClick={this.props.clickaction} key={arr.filterid} filter={arr.filterid} >{arr.title}</button>
    }

    render(){

        return(
            <React.Fragment>
                <h1 className="signature mb-4">{strings.realizations.filterbar.title}</h1>
                <div className="row no-gutters filter-bar mb-4">
                    {this.props.filterlist.map(this.mapFilter)}
                </div>
            </React.Fragment>

        )
    }
}

export default FilterBar;