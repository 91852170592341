import React,{Component} from 'react';

class Staticimagesrow extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <section>
                <div className="container-fluid px-0">
                    <div className="row no-gutters">
                        {(this.props.images).map(this.mapImages)}
                    </div>
                </div>
            </section>
        )
    }

    mapImages = (value,key) => {
        const imageurl = `url('/images/${value}')`;
        const imageurlh = `url('/images/h_${value}')`;
        const grid = 12;
        const numxrow = Math.round(grid/this.props.images.length);
        return <div key={key} className={`col-lg-${numxrow} squared static-image hover`} style={{backgroundImage : imageurl}}>
                    <div style={{backgroundImage : imageurlh}}></div>
                </div>
    }

}

export default Staticimagesrow