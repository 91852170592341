import React, {Component} from 'react';
import Mymodal from './Mymodal';
import { Link } from "react-router-dom";
import strings from '../langs/strings'
import ReactHtmlParser from 'react-html-parser';

class Footer extends Component{

    constructor(props){
        super(props);
        this.toggleModal = this.toggleModal.bind(this)
        this.state = {
            modal_is_show : false
        }
    }

    render(){
        return(
            <footer id="footer">
                <div className="contacts py-4">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-md my-2 my-md-0"><i className="lnr lnr-map-marker"/>Via del Dosso 1, Carate Brianza MB</div>
                            <div className="col-md my-2 my-md-0"><i className="lnr lnr-phone-handset"/>+39 0362 8000 88</div>
                            <div className="col-md my-2 my-md-0"><i className="lnr lnr-envelope"/>info@cosmelux.it</div>
                            <div className="col-md my-2 my-md-0"><i className="lnr lnr-star"/>
                                <Link to={`/${strings.privacypolicy.href }`} target="_blank" >{strings.privacypolicy.text}</Link>
                                {/*<Link to={`/${this.props.sitelang + '/' + strings.privacypolicy.href }`} >{strings.privacypolicy.text}</Link>*/}
                            </div>
                            <div className="col-md my-2 my-md-0"><button className="b-w text-uppercase btn-block py-2" onClick={this.toggleModal}>{strings.footer.contactbutton}</button></div>
                        </div>
                    </div>
                </div>
                <div className="p-4 text-center d-block">
                {strings.footer.datas}
                <small className="disclaimer">{ReactHtmlParser(strings.footer.disclaimer)}</small>
                </div>
                <Mymodal parent={this} show={this.state.modal_is_show}/>
    
            </footer>
        )  
    }

    toggleModal() {
        this.setState({modal_is_show : !this.state.modal_is_show});
    }   

}

export default Footer;