import React, {Component} from 'react';
import Pageheader from './Pageheader';
import ImageCarousel from './ImagesCarousel'
import strings from '../langs/strings'
import ReactHtmlParser from 'react-html-parser'





class UvMetallizazion extends Component{

    render(){

        return(
            <React.Fragment>
            <Pageheader lang="it" small={strings.uvmetallization.title.small} title={strings.uvmetallization.title.text}  list={strings.uvmetallization.title.list} bgimage='metal-cover.jpg'/>
            <section className="py-5 gold-circle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <p className="text-justify">{ReactHtmlParser(strings.uvmetallization.intro.text)}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 grey-shot">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 text-left">
                            <h1 className="signature title mb-5">{strings.uvmetallization.advantages.title}</h1>
                            <ul className="lined">
                                {strings.uvmetallization.advantages.list.map((el,key) => {
                                return <li key={key} >{el}</li>
                                })}
                            </ul>
                        </div>
                        <div className="col-lg-6 text-left">
                            <h2 className="classic title">{ReactHtmlParser(strings.uvmetallization.productivecapacity.title)}</h2>
                            <p>{strings.uvmetallization.productivecapacity.text}</p>
                            <h2 className="classic title">{ReactHtmlParser(strings.uvmetallization.piecestype.title)}</h2>
                            <p>{ReactHtmlParser(strings.uvmetallization.piecestype.text)}</p>
                            <h2 className="classic title">{ReactHtmlParser(strings.uvmetallization.companycert.title)}</h2>
                            <p>{strings.uvmetallization.companycert.text}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                <div className="row d-flex align-items-start my-5">
                        <div className="col-lg-6">
                            <h2 className="classic title text-left mb-3">
                            <small className="text-uppercase d-block">{strings.uvmetallization.termevapimplant.title.small}</small>
                                <strong className="gold">{strings.uvmetallization.termevapimplant.title.text}</strong></h2>
                            <p className="text-justify">{ReactHtmlParser(strings.uvmetallization.termevapimplant.description)} </p>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="classic title text-left mb-3">
                            <small className="text-uppercase d-block">{strings.uvmetallization.sputtering.title.small}</small>
                                <strong className="gold">{strings.uvmetallization.sputtering.title.text}</strong></h2>
                            <p className="text-justify">{ReactHtmlParser(strings.uvmetallization.sputtering.description)} </p>
                        </div>
                    </div>
                </div>
            </section>


            <ImageCarousel dir='uv metallization' images={['metal-1-min.jpg' , 'metal-2-min.jpg' , 'metal-3-min.jpg' , 'metal-4-min.jpg' , 'metal-5-min.jpg' , 'metal-6-min.jpg' , 'metal-8-min.jpg' , 'metal-9-min.jpg']} />
            </React.Fragment>
        )
    }
}

export default UvMetallizazion;