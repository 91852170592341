import React, {Component} from 'react';
import Textwithimage from './homepage/Textwithimage';
import Mainslider from './homepage/Mainslider';
import Carousel from './homepage/Carousel';
import Staticimagesrow from './Staticimagesrow'
import strings from './../langs/strings'
import ReactHtmlParser from 'react-html-parser';


class Homepage extends Component{

    render(){
        return(
            <React.Fragment>
            <Mainslider strings={this.props.strings.slider} />
            <section className="hp_section_1">
                <div className="container-fluid bg black bg-1">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-8 my-5 py-5" >
                            <h1 className="signature gold big mt-4 mt-md-0">{this.props.strings.homepage.perfectfinishing.title}</h1>
                            <p className="w-75 ml-md-auto mx-auto mt-5">
                                {ReactHtmlParser(strings.homepage.perfectfinishing.description)}
                            </p>
                        </div>
                        <div className="col-lg-4 py-5">
                            {/*<img src="/images/cd_cap.png" alt="" className="w-75 ml-auto d-block mr-4"/>*/}
                        </div>
                    </div>
                </div>
            </section>    

            <section className="bg light-grey py-5">
                <div className="container">
                    <div className="row">
                        <Textwithimage icon='magic-wand' title={strings.homepage.icons.magicwand.title} description={strings.homepage.icons.magicwand.description} />
                        <Textwithimage icon='star' title={strings.homepage.icons.star.title} description={strings.homepage.icons.star.description} />
                        <Textwithimage icon='diamond' title={strings.homepage.icons.diamond.title} description={strings.homepage.icons.diamond.description} />
                        <Textwithimage icon='bucket' title={strings.homepage.icons.bucket.title} description={strings.homepage.icons.bucket.description} />
                    </div>
                </div>
            </section>
            <Staticimagesrow images={['product_1.jpg' , 'product_2.jpg' , 'product_3.jpg' , 'product_4.jpg']}/>
            <Carousel />

            </React.Fragment>
        )
    }
}

export default Homepage;