import React, {Component} from 'react';
import { Link } from "react-router-dom";
import strings from '../langs/strings'


class Navbar extends Component{

    constructor(props){
        super(props);
        
        this.toggleMenu = this.toggleMenu.bind(this);

        this.switchLang = this.switchLang.bind(this)


        this.state = {
            visible: this.props.visible,
            language: this.props.sitelang
        };
    }

    handleMenuClose = () => {
        this.props.parentCallback(!this.state.visible);
    }

    render(){
 
        return(
            <nav className="d-flex align-items-center px-5" id="primary_nav">
                <ul>
                    <li>
                        <Link to={`/${this.state.language}`}  onClick={this.handleMenuClose}>{strings.navigation.home.text}</Link>
                    </li>
                    <li>
                        <Link to={`/${this.state.language + '/' + strings.navigation.about.href}`} onClick={this.handleMenuClose}>{strings.navigation.about.text}</Link>
                    </li>
                    <li>
                        <Link to={`/${this.state.language + '/' + strings.navigation.uvlacquering.href}`} onClick={this.handleMenuClose}>{strings.navigation.uvlacquering.text}</Link>
                    </li>
                    <li>
                        <Link to={`/${this.state.language + '/' + strings.navigation.uvmetallization.href}`} onClick={this.handleMenuClose}>{strings.navigation.uvmetallization.text}</Link>
                    </li>
                    <li>
                        <Link to={`/${this.state.language + '/' + strings.navigation.rd.href}`} onClick={this.handleMenuClose}>{strings.navigation.rd.text}</Link>
                        
                    </li>
                    <li>
                        <Link to={`/${this.state.language + '/' + strings.navigation.realizations.href}`} onClick={this.handleMenuClose}>{strings.navigation.realizations.text}</Link>
                    </li>
                    <li>
                        <Link to={`/${this.state.language + '/' + strings.navigation.references.href}`}onClick={this.handleMenuClose} >{strings.navigation.references.text}</Link>
                    </li>
                    <li>
                        <Link to={`/${this.state.language + '/' + strings.navigation.contacts.href}`} onClick={this.handleMenuClose} >{strings.navigation.contacts.text}</Link>
                    </li>
                    <li>
                        <Link to={`/${this.state.language + '/' + strings.navigation.career.href}`} onClick={this.handleMenuClose} >{strings.navigation.career.text}</Link>
                    </li>
                </ul>

                <div className="langs px-5">
                    <Link to={`/it`} onClick={this.switchLang} lang="it">IT</Link>
                    <Link to={`/en`} onClick={this.switchLang} lang="en">EN</Link>
                    {/*<Link to={`/de`} onClick={this.switchLang} lang="de">DE</Link>*/}
                    {/*<Link to={`/fr`} onClick={this.switchLang} lang="fr">FR</Link>*/}
                </div>
            </nav>
        );
    }

    componentDidUpdate(){

        if(this.props.visible !== this.state.visible){

            this.toggleMenu(this.props.visible);
            this.setState(()=>{
                let new_state = !this.state.visible;

                return {visible : new_state}
            })

        }

    }

    switchLang(e){
        let newlang = e.target.lang

        this.setState({
            language : e.target.lang
        })
        return this.props.handleLanguageSwitch(newlang)
    }


    componentDidMount(){

        this.toggleMenu();

        /*var menu_overlay = document.createElement('div');
        menu_overlay.setAttribute("id" , "menu-overlay");
        document.body.appendChild(menu_overlay);*/

    }


    toggleMenu(state = this.state.visible){


        const primary_nav = document.getElementById("primary_nav");
        const menu_overlay = document.getElementById("menu-overlay");

        if(state){
            primary_nav.classList.add("active")
            menu_overlay.classList.add("active");
        } else {
            primary_nav.classList.remove("active")
                menu_overlay.classList.remove("active");
        };

    }




}


export default Navbar;