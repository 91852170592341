import React, {Component} from 'react';
import Modal from 'react-modal';
import Contactform from './Contactform'

class myModal extends Component {

    constructor(props){
        super(props);

        this.checkUpdate = this.checkUpdate.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

        this.parent = this.props.parent;
        
        this.state = {
            show : false
        }
    }

    componentDidMount(){
        Modal.setAppElement('#app');
    }

    componentDidUpdate(){
        this.checkUpdate(this.props.show);
    }

    render(){
        return(
            <Modal  isOpen={this.state.show} contentLabel="" className="modal-content container" overlayClassName="big-modal bg-blk d-flex align-items-center" >
            <button className="popup-close" onClick={this.parent.toggleModal}></button>
            <Contactform />
            </Modal>
        )
    }

    handleCloseModal() {
        console.log(this.state)
        this.setState(() => {
            return {show : false}
        })
    }

    checkUpdate(newstate) {
        if(newstate !== this.state.show) {
            this.setState(() => {
                return {show : newstate}
            })
        };
    }

}

export default myModal;