import React,{Component} from 'react';
import strings from '../langs/strings'

const API_PATH = 'http://localhost:3002/contact/';

class Careerform extends Component{

    constructor(state,props){

        super(state,props);

        this.state = {
            ufname : '',
            ulname : '',
            uemail : '',
            uphone : '',
            umessage : '',
            ucv : '',
            uprivacy : false,
            unl : false,
            mailsent : false,
            error : [],
            cansubmit : false
        }

    }

    render(){


        return(
            <form className="row" id="careerform">
                
                <div className="col-lg-6">
                    <label>{strings.careerform.firstname}</label>
                    <input type="text" required name="ufname" onChange={this.updateInputState} value={this.state.ufname}/>
                </div>   

                <div className="col-lg-6">
                    <label>{strings.careerform.lastname}</label>
                    <input type="text" name="ulname" onChange={this.updateInputState}  value={this.state.ulname}/>
                </div> 

                <div className="col-lg-6">
                    <label>{strings.careerform.emailaddress}</label>
                    <input type="email" required name="uemail" onChange={this.updateInputState} onBlur={this.checkField} value={this.state.uemail}/>
                </div>

                <div className="col-lg-6">
                    <label>{strings.careerform.phone}</label>
                    <input type="tel" name="uphone" onChange={this.updateInputState} value={this.state.uphone}/>
                </div>

                <div className="col-lg-12">
                    <label>{strings.careerform.message}</label>
                    <textarea rows="5" required name="umessage" onChange={this.updateInputState} value={this.state.umessage}></textarea>
                </div>

                <div className="col-lg-12">
                    <label>{strings.careerform.cvupload}</label>
                    <input type="file" name="ucv" onChange={this.updateInputState} value={this.state.ucv} />
                </div>

                <div className="col-lg-12">
                    <div className="checkbox-label mr-2">
                        <label htmlFor="uprivacy"></label>
                        <input type="checkbox" name="uprivacy" onChange={this.updateInputState} required id="uprivacy"/>
                    </div>
                    {strings.formatString(strings.careerform.privacy, <u>{strings.careerform.privacyu}</u>)}
                </div>

                <div className="col-lg-12 mb-2">
                    <div className="checkbox-label mr-2">
                        <label htmlFor="unl"></label>
                        <input type="checkbox" name="unl" onChange={this.updateInputState} id="unl"/>
                    </div>
                    {strings.careerform.newsletter}
                </div>

                <small className="col-lg-12 mb-4"><u>{strings.careerform.requiredfields}</u></small>


                {this.state.mailsent === false && 
                <div className="col-lg-12 text-center">
                    <button type="submit" className="text-uppercase px-4 py-2" onClick={this.handleFormSubmit}>{strings.careerform.sendbutton}</button>
                </div>
                }

                {this.state.mailsent === true && 
                    <div className="col-lg-12 text-center my-3 thx">
                        {strings.formatString(strings.careerform.thx,<span>{this.state.uflname}</span>,<span>{this.state.uemail}</span>)}
                    </div>
                }

                {this.state.error.length > 0 && 
                    <div className="col-lg-12 text-center my-3 error">{strings.careerform.error}</div>
                }
                

            </form>
        )
    }



    updateInputState = (e) => {

        let inputtype = e.target.type;
        let updatestate;

        console.log(e.target.name);
        console.log(e.target.value);
        

        switch(inputtype){
            case 'checkbox' : 
            updatestate = `{"${e.target.name}":${e.target.checked}}`;

            let labelsel = document.querySelector(`label[for=${e.target.name}]`);
            labelsel.classList.toggle('active')

            break;
            default : updatestate = `{"${e.target.name}":"${e.target.value}"}`
        }

        return this.setState(JSON.parse(updatestate));
    }

    checkRequiredField(el){

        let tname = el.name;
        let ttype = el.type;
        let tval;
        
        switch(ttype){
            case 'checkbox' : 
                tval = el.checked;
            break;
            default :
                tval = el.value;
        }

        if(!tval){

            return tname;

        }

    };


    handleFormSubmit = e => {
        e.preventDefault();
        const formid = document.getElementById('careerform');
        let errors = [];

        for(var i=0; i < formid.elements.length; i++){ //cycling all form fields

            let element = formid.elements[i];

            if(element.required){ //if required, control it

                if(this.checkRequiredField(element)){ //if error, will return field name
                    errors.push(this.checkRequiredField(element));  //push into temporary error array
                };

            }

        }

        this.setState({error : errors}) //finally, async push error field updating state of the component

        if(errors.length <= 0){ //no errors, can submit it, better to update state before...just in case
            this.setState({cansubmit : true} , () => this.submitIt())
        }


    };

    submitIt = e =>{

        fetch(`${API_PATH}`, {
            method: 'POST',
            body: JSON.stringify(this.state)
          }).then((resp) =>{
              return resp.json();
          }).then((response) => {

              this.setState({mailsent : response.mailsent})

          })
    }
}

export default Careerform;