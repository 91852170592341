import React, {Component} from 'react';

class ImagesCarousel extends Component{

    constructor(props,state){
        super(props,state)
    }

    componentDidMount(){
        const $ = window.jQuery;    
        let owl = $('.owl-carousel.image-carousel');
        let owlopts = {

            loop: true,
            autoplay: false,
            nav: false,
            dots: false,
            responsiveClass:true,
            responsive:{
                0:{
                    items:2,
                },
                600:{
                    items:2,
                },
                1000:{
                    items:4,
                }
            }
        }

        owl.owlCarousel(owlopts);


    }

    render(){
        return(
            <div className="owl-carousel image-carousel square-img">
                {this.props.images.map(this.renderizeItem)}
            </div>
        )
    }

    renderizeItem = (item, key) => {
        let bgurl = `url('/images/${this.props.dir+'/'+item}')`;

        return <div key={key} className="item" style={{backgroundImage : bgurl}}></div>
    }
}

export default ImagesCarousel