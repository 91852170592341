import React, {Component} from 'react';
import Pageheader from './Pageheader';
import Staticimagesrow from './Staticimagesrow'
import strings from '../langs/strings'
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from 'react-helmet'



class UvLacquering extends Component{


    render(){
        return(
            <React.Fragment>

            <Helmet>
                <title>{strings.uvlacquering.meta.title}</title>
                <meta name="description" content={strings.uvlacquering.meta.description} />
            </Helmet>

            <Pageheader lang="it" small={strings.uvlacquering.title.small} title={strings.uvlacquering.title.text} list={strings.uvlacquering.title.list} bgimage='lacquering-cover.jpg'/>
            <section className="py-5 gold-circle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <p className="text-justify">
                                {ReactHtmlParser(strings.uvlacquering.intro.text)}
                            </p>
                        </div>
                        <div className="col-lg-3">
                            <img alt='cosmelux-company' src="/images/laccatura-1.png" className="w-100"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 grey-shot">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 text-left">
                            <h1 className="signature title mb-5">{strings.uvlacquering.advantages.title}</h1>
                            <ul className="lined">
                                {strings.uvlacquering.advantages.list.map((val,key) => {
                                return <li key={key}>{val}</li>
                                })}
                            </ul>
                        </div>
                        <div className="col-lg-6 text-left">
                            <h2 className="classic title">{ReactHtmlParser(strings.uvlacquering.productivecapacity.title)}</h2>
                            <p>{strings.uvlacquering.productivecapacity.text}</p>
                            <h2 className="classic title">{ReactHtmlParser(strings.uvlacquering.piecestype.title)}</h2>
                            <p>{strings.uvlacquering.piecestype.text}</p>
                            <h2 className="classic title">{ReactHtmlParser(strings.uvlacquering.companycert.title)}</h2>
                            <p>{strings.uvlacquering.companycert.text}</p>
                        </div>
                    </div>
                </div>
            </section>
            <Staticimagesrow images={['uv-lacquering-3.jpg' , 'uv-lacquering-1.jpg' , 'uv-lacquering-4.jpg' , 'uv-lacquering-2.jpg']}/>
            </React.Fragment>
        )
    }
}

export default UvLacquering;