import React, {Component} from 'react';
import strings from './../../langs/strings'

class Carousel extends Component{

    componentDidMount(){
        const $ = window.jQuery;    
        let owl = $('.owl-carousel');
        let owlopts = {
            items: 1,
            loop: false,
            autoplay: false,
            nav: false,
            dots: false
        }

        owl.owlCarousel(owlopts);

        $(".squared-navigation span").on("click" , function(){
            let action = $(this).attr("class");

            if(action === "next"){
                owl.trigger('next.owl.carousel');
            } else {
                owl.trigger('prev.owl.carousel');
            }

            
        });

    }

    render(){
        return(
            <section>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6" style={{backgroundImage : "url('/images/cosmelux-2.jpg')"  }}></div>
                    <div className="col-lg-6 bg black">
                        <div className="owl-carousel px-0 px-md-5 my-5" id="hp-bottom-carousel">
                            {strings.homepage.carousel.map( (item, key) => {
                            return (
                                <div key={key}>
                                    <h1>{item.title}</h1>
                                    <p>{item.description}</p>
                                </div>
                            )
                            })}
                        </div>
                        {/*<div className="squared-navigation d-flex justify-content-center mb-5 mt-3" data-owl="#hp-bottom-carousel">
                            <span className="prev"></span>
                            <span className="next"></span>
                        </div>*/}
                    </div>
                </div>
            </div>
            </section>
        )
    }
}

export default Carousel;