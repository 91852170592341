import React, {Component} from 'react';
import Pageheader from './Pageheader';
import ContactForm from './Contactform';
import strings from '../langs/strings'

import Gmap from './Gmap'


class Contacts extends Component{




    render(){

        return(
            <React.Fragment>
            <Pageheader lang="it" small={strings.contacts.title.small} title={strings.contacts.title.text} bgimage='contact-cover.jpg'/>
            <section>
                <div className="gmap">

                <Gmap />

                </div>
            </section>
            <section className="my-5">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-4 mb-md-0">
                            <ContactForm />
                        </div>
                        <div className="col-lg-4 text-left">
                        <h4 className="classic title text-left"><strong>Cosmelux</strong> s.r.l.</h4>
                        <p>
                        <small className="text-uppercase d-block">{strings.contacts.sidebar.address}</small>
                        Via Del Dosso 1 - 20841 Carate Brianza (MB)
                        <small className="text-uppercase d-block mt-1">{strings.contacts.sidebar.phone}</small>
                        0362 / 800088
                        <small className="text-uppercase d-block mt-1">fax</small>
                        0362 / 975169
                        <small className="text-uppercase d-block mt-1">{strings.contacts.sidebar.socdata}</small>
                        P.IVA / C.F. 03460330966
                        </p>
                        </div>
                    </div>
                </div>

            </section>


            </React.Fragment>
        )
    }
}

export default Contacts;