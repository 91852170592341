import React,{Component} from 'react';

class Pageheader extends Component{

    constructor(props){
        super(props);

        props = {
            bgpos : 'center center'
        }
    }

    render(){

        const fullbg = `url('/images/${this.props.bgimage}')`;

        return(
            <section className="page-header py-5" style={{backgroundImage : fullbg , backgroundPosition : this.props.bgpos}}>
                <div className="container">
                    <div className="row">
                         <div className="col text-left">
                            <h1 className="text-left d-inline-block">
                                <small className="d-block">{this.props.small}</small>
                                {this.props.title}
                                {this.props.list &&
                                    <ul className="text-uppercase text-right mt-5">
                                        {(this.props.list).map(this.writeLi)}
                                    </ul>
                                }

                            </h1>
                         </div>
                    </div>
                </div>
            </section>
        )
    }

    writeLi = (value,index) =>{
    return <li key={index}>{value}</li>
    }

}

export default Pageheader;